import dashboardRoutes from './views/dashboard/DashboardRoutes';
import NotFound from './views/sessions/NotFound';
import sessionRoutes from './views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import mastersRoutes from './views/masters/mastersRoutes';
import profileRoutes from './views/profile/ProfileRoutes';
import { AuthGuard } from './auth/AuthGuard';
import billRoutes from './views/bill/BillRoutes';
import driverRoutes from './views/Driver/DriverRouter';
import LoadingRoute from './views/loadingUnloading/LoadingRoute';
import ReportRoute from './views/reports/ReportRoute';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...mastersRoutes,
      ...profileRoutes,
      ...billRoutes,
      ...driverRoutes,
      ...LoadingRoute,
      ...ReportRoute,
    ],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="admindashboard" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
