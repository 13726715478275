import { authRoles } from './auth/authRoles';

export const navigations = [
  { name: 'Dashboard', path: '/admindashboard', icon: 'dashboard', auth: authRoles.GUEST },

  // { name: 'Delivery', path: '/delivery', icon: 'delivery_dining', auth: authRoles.DRIVER },
  { name: 'Loading', path: '/loading', icon: 'local_shipping', auth: authRoles.ADMIN },
  { name: 'Unloading', path: '/unloading', icon: 'rv_hookup', auth: authRoles.ADMIN },
  { label: 'Reports', type: 'label', auth: authRoles.SA },
  {
    name: 'Reports',
    icon: 'receipt_long',
    children: [
      { name: 'Daily Sale', iconText: 'Sale', path: '/dailysale', auth: authRoles.SA },
      { name: 'Delivery Report', iconText: 'Report', path: '/deliveryreport', auth: authRoles.SA },
    ],
  },

  { label: 'MASTER', type: 'label', auth: authRoles.ADMIN },
  {
    name: 'Masters',
    icon: 'format_list_bulleted',
    children: [
      {
        name: 'Company',
        iconText: 'Company',
        path: '/master/consignor',
        auth: authRoles.ADMIN,
      },

      // { name: 'Route', iconText: 'Route', path: '/master/routes', auth: authRoles.ADMIN },
      { name: 'Driver', iconText: 'Driver', path: '/master/driver', auth: authRoles.ADMIN },
      { name: 'Vehicle', iconText: 'Vehicle', path: '/master/Vehicle', auth: authRoles.ADMIN },
    ],
  },

  { label: 'Bill', type: 'label', path: '/bill', auth: authRoles.SA },
  {
    name: 'Bill Reports',
    icon: 'receipt_long',
    path: '/bill',
    auth: authRoles.SA,
    // children: [{ name: 'Create Bill', iconText: 'Bill', path: '/bill', auth: authRoles.SA }],
  },

  { label: 'Components', type: 'label', auth: authRoles.SA },
  {
    name: 'User Info',
    icon: 'person_add',
    children: [
      {
        name: 'Register User',
        iconText: 'R',
        path: '/signup',
        auth: authRoles.SA,
      },
      {
        name: 'Users List',
        iconText: 'L',
        path: '/userlist',
        auth: authRoles.SA,
      },
    ],
  },
];
