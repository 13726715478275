export const SET_USER_NAVIGATION = 'SET_USER_NAVIGATION';

const getfilteredNavigations = (navList = [], role) => {
  return navList.reduce((array, nav) => {
    if (nav.auth && nav.auth.includes(role)) {
      array.push(nav);
    } else if (nav.children) {
      const filteredChildren = getfilteredNavigations(nav.children, role);
      if (filteredChildren.length > 0) {
        nav.children = filteredChildren;
        array.push(nav);
      }
    } else if (nav.allowedRoles && nav.allowedRoles.includes(role)) {
      array.push(nav);
    }
    return array;
  }, []);
};

export function getNavigationByUser() {
  return (dispatch, getState) => {
    const { user, navigations = [] } = getState();

    const filteredNavigations = getfilteredNavigations(navigations, user?.role);

    dispatch({
      type: SET_USER_NAVIGATION,
      payload: [...filteredNavigations],
    });
  };
}
