import { ADD_STOCK, STOCK } from '../constant/constant';

export const StockReducer = function (state = {}, action) {
  switch (action.type) {
    case STOCK: {
      return action.payload;
    }
    case ADD_STOCK: {
      if (state._id === action.payload._id) {
        return {
          ...state,
          ...action.payload,
        };
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
};
