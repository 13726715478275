import Loadable from '../../components/Loadable';
import { lazy } from 'react';

const Loading = Loadable(lazy(() => import('./Loading')));
const Unloading = Loadable(lazy(() => import('./Unloading')));
const Delivery = Loadable(lazy(() => import('./Delivery')));

const LoadingRoute = [
  { path: '/loading', element: <Loading /> },
  { path: '/unloading', element: <Unloading /> },
  { path: '/delivery', element: <Delivery /> },
];

export default LoadingRoute;
