import { CREATE_ROUTE, GET_ROUTE } from '../constant/constant';

export const RouteReducer = function (state = [], action) {
  switch (action.type) {
    case GET_ROUTE: {
      return action.payload;
    }

    case CREATE_ROUTE: {
      return [...state, action.payload];
    }

    // case DELETE_ROUTE: {
    //   return state.filter(({ _id }) => _id !== action.payload._id);
    // }
    // case EDIT_ROUTE: {
    //   return state.map((state) => {
    //     if (state._id === action.payload._id) {
    //       return {
    //         ...state,
    //         ...action.payload,
    //       };
    //     } else {
    //       return state;
    //     }
    //   });
    // }
    default: {
      return state;
    }
  }
};
