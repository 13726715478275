import Loadable from '../../components/Loadable';
import { lazy } from 'react';

const Consignor = Loadable(lazy(() => import('./consignor/consignor')));
const Driver = Loadable(lazy(() => import('./driver/Driver')));
const Vehicle = Loadable(lazy(() => import('./vehicle/Vehicle')));

const consignorRoutes = [
  { path: '/master/consignor', element: <Consignor /> },
  { path: '/master/driver', element: <Driver /> },
  { path: '/master/Vehicle', element: <Vehicle /> },
];

export default consignorRoutes;
