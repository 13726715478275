import Loadable from '../../components/Loadable';
import { lazy } from 'react';

const CreateBill = Loadable(lazy(() => import('./createbill/CreateBill')));
const PrintBill = Loadable(lazy(() => import('./createbill/PrintBill')));

const billRoutes = [
  { path: '/bill', element: <CreateBill /> },
  { path: '/billdetails', element: <PrintBill /> },
];

export default billRoutes;
