import { Icon, IconButton, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
// import { topBarHeight } from 'app/utils/constant';
import React, { useState } from 'react';
import { topBarHeight } from '../utils/constant';
import { useNavigate } from 'react-router-dom';
import useSettings from '../hooks/useSettings';
import { useDispatch } from 'react-redux';
import { TRACK_BILTY } from '../redux/constant/constant';

const SearchContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 9,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: topBarHeight,
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  '&::placeholder': {
    color: theme.palette.text.primary,
  },
}));

const SearchInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  outline: 'none',
  fontSize: '1rem',
  paddingLeft: '20px',
  height: 'calc(100% - 5px)',
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  '&::placeholder': { color: theme.palette.text.primary },
}));

const MatxSearchBox = () => {
  const dispatch = useDispatch();

  // const { settings, updateSettings } = useSettings();
  // const updateSidebarMode = (sidebarSettings) => {
  //   updateSettings({
  //     layout1Settings: { leftSidebar: { ...sidebarSettings } },
  //   });
  // };

  // const theme = useTheme();
  // const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  // const handleSidebarToggle = () => {
  //   let { layout1Settings } = settings;
  //   let mode;
  //   if (isMdScreen) {
  //     mode = layout1Settings.leftSidebar.mode === 'close' ? 'close' : 'mobile';
  //   } else {
  //     mode = layout1Settings.leftSidebar.mode === 'full' ? 'close' : 'full';
  //   }
  //   updateSidebarMode({ mode });
  // };

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
    // handleSidebarToggle();
  };

  const { palette } = useTheme();
  const textColor = palette.text.primary;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event.target.value);
    }
  };

  const handleSubmit = (e) => {
    navigate(`/biltyinfo/?bilty=${e}`);
    // dispatch({
    //   type: TRACK_BILTY,
    //   payload: e,
    // });
  };

  return (
    <React.Fragment>
      {!open && (
        <IconButton onClick={toggle}>
          <Icon sx={{ color: textColor }}>search</Icon>
        </IconButton>
      )}

      {open && (
        <SearchContainer>
          <SearchInput
            type="number"
            placeholder="Search Bilty..."
            autoFocus
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <IconButton onClick={toggle} sx={{ mx: 2, verticalAlign: 'middle' }}>
            <Icon sx={{ color: textColor }}>close</Icon>
          </IconButton>
        </SearchContainer>
      )}
    </React.Fragment>
  );
};

export default MatxSearchBox;
