import { combineReducers } from 'redux';
import { RouteReducer } from './RouteReducer';
import { DriverReducer, VehicleReducer } from './DriverVehicle';
import NavigationReducer from './NavigationReducer';
import { NorReducer } from './NorReducer';
import LoadingReducer from './LoadingRuducer';
import { StockReducer } from './StockReducer';
import { billData, billList } from './BillReducer';

const RootReducer = combineReducers({
  stockData: StockReducer,
  nordata: NorReducer,
  routeData: RouteReducer,
  driverdata: DriverReducer,
  vehicledata: VehicleReducer,

  billData: billData,
  billList: billList,

  loadingData: LoadingReducer,
  navigations: NavigationReducer,
});

export default RootReducer;
