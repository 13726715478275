import Loadable from '../../components/Loadable';
import { lazy } from 'react';

const LoadingReport = Loadable(lazy(() => import('./LoadingReport')));
const DailySales = Loadable(lazy(() => import('./DailySales')));
const DeliveryReport = Loadable(lazy(() => import('./DeliveryReport')));

const ReportRoute = [
  { path: '/loadingreport', element: <LoadingReport /> },
  { path: '/deliveryreport', element: <DeliveryReport /> },
  { path: '/dailysale', element: <DailySales /> },
];

export default ReportRoute;
