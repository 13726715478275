export const STOCK = 'STOCK';
export const ADD_STOCK = 'ADD_STOCK';

// bill
export const BILL_DATA = 'BILL_DATA';
export const BILL_LIST = 'BILL_LIST';
export const SET_LEDGER_BILL = 'SET_LEDGER_BILL';

// Route
export const GET_ROUTE = 'GET_ROUTE';
export const CREATE_ROUTE = 'CREATE_ROUTE';
export const DELETE_ROUTE = 'DELETE_ROUTE';
export const EDIT_ROUTE = 'EDIT_ROUTE';

// CONSIGNOR TYPES
export const CREATE_NOR = 'CREATE_NOR';
export const GET_NOR = 'GET_NOR';
export const DELETE_NOR = 'DELETE_NOR';
export const EDIT_NOR = 'EDIT_NOR';

// DRIVER TYPE
export const GET_DRIVER = 'GET_DRIVER';
export const CREATE_DRIVER = 'CREATE_DRIVER';
export const EDIT_DRIVER = 'EDIT_DRIVER';
export const DELETE_DRIVER = 'DELETE_DRIVER';

// DRIVER TYPE
export const GET_VEHICLE = 'GET_VEHICLE';
export const CREATE_VEHICLE = 'CREATE_VEHICLE';
export const EDIT_VEHICLE = 'EDIT_VEHICLE';
export const DELETE_VEHICLE = 'DELETE_VEHICLE';

// Loading
export const Loading_API = 'Loading_API';

// Routes data
